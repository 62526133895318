.hero-panel {
    height: 100vh;
    width: 100%;
    object-fit: contain;
    background: linear-gradient(#8fffaf, #60c3e1);
    background-repeat: no-repeat;
    background-attachment: fixed;
    border-radius: 10% 30% 50% 70%;
}

.hero-card {
    position: absolute;
    margin-top: 10%;
    background-color: black;
    height: 70%;
    width: 70%;
    margin-left: 15%;
    border-radius: 16px;
    background: linear-gradient(0.25turn, rgba(255, 255, 255, 0.19), rgba(255, 234, 215, 0.28));
    box-shadow: 8px 8px 2px 1px rgba(57, 57, 255, 0.15);
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    padding: 2%;
}

.hero-card-two {
    position: absolute;
    margin-top: 56%;
    background-color: black;
    height: 70%;
    width: 70%;
    margin-left: 15%;
    border-radius: 16px;
    background: linear-gradient(0.25turn, rgba(255, 255, 255, 0.19), rgba(255, 234, 215, 0.28));
    box-shadow: 8px 8px 2px 1px rgba(57, 57, 255, 0.15);
    backdrop-filter: blur( 2px );
    -webkit-backdrop-filter: blur( 2px );
    padding: 2%;
}

.container {
  margin: auto;
}

.dot-one {
  margin: 12%;
  position: absolute;
  height: 300px;
  width: 300px;
  background-color: rgba(21, 0, 255, 0.04);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 2px 1px 2px 1px rgba(251, 255, 209, 0.95),
    0 0 40px 40px rgba(255, 244, 214, 0.154);
  animation-name: dot-one-anim;
  animation-duration: 8s;
  animation-iteration-count: 1;
}

.dot-one-about {
  margin: 12%;
  position: absolute;
  height: 300px;
  width: 300px;
  background-color: rgba(21, 0, 255, 0.04);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 2px 1px 2px 1px rgba(251, 255, 209, 0.95),
    0 0 40px 40px rgba(255, 244, 214, 0.154);
}

@keyframes dot-one-anim {
  0%   {margin-top: 8%; margin-left: 8%;}
  100%   {margin-top: 12%; margin-left: 12%;}
}

.dot-two {
  margin-top: 20%;
  margin-left: 60%;
  position: absolute;
  height: 200px;
  width: 200px;
  background-color: rgba(6, 175, 104, 0.1);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 1px 1px 2px 1px rgba(251, 255, 209, 0.95),
  0 0 40px 40px rgba(255, 244, 214, 0.154);
  animation-name: dot-two-anim;
  animation-duration: 6s;
  animation-iteration-count: 1;
}

.dot-two-about {
  margin-top: 20%;
  margin-left: 60%;
  position: absolute;
  height: 200px;
  width: 200px;
  background-color: rgba(6, 175, 104, 0.1);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 1px 1px 2px 1px rgba(251, 255, 209, 0.95),
  0 0 40px 40px rgba(255, 244, 214, 0.154);
}

@keyframes dot-two-anim {
  0%   {margin-top: 20%; margin-left: 65%;}
  100%   {margin-top: 20%; margin-left: 60%;}
}

.text {
    margin: auto 0;
    margin-left: 10%;
}

.title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.description {
    color: rgba(0, 0, 0, 0.8);
    line-height: 50px;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.image {
  margin: auto;
}

.image {
    border-radius: 70%;
    height: 350px;
    width: 350px;
    opacity: 0.95;
}

@media only screen and (max-width: 768px) {
  .dot-one {
    visibility: hidden;
  }
  .dot-two {
    visibility: hidden;
  }
  .text {
    margin-left: 0px;
    margin-top: 10px;
  }

  .title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 30px;
  }
  .description {
    color: rgba(0, 0, 0, 0.8);
    line-height: 40px;
    font-size: 20px;
  }
  .hero-card {
    margin-top: 100px;
    height: 70%;
    width: 75%;
    margin-left: 12.5%;
  }

  .hero-panel {
    border-radius: 15% 50% 55% 70%;
    height: 90vh;
    background: linear-gradient(rgba(143, 255, 175, 0.75), rgba(96, 195, 225, 0.75));
  }

  .image {
    margin-top: -50px;
    margin-left: -10px;
    height: 300px;
    width: 300px;
  }
}