
.header {
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

.header-bg {
    background-color: transparent;
    transition: .5s;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 2.1rem;
    font-weight: 600;
    color: rgb(186, 182, 182);
}

.logo {
    color: rgb(54, 54, 54);
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    font-weight: bold;
    letter-spacing:0.13rem;
    text-decoration: none;
}

.logo:hover {
    visibility: none;
    text-decoration: none;
    color: rgb(54, 54, 54);;
}

.menu-icon{
    color: rgb(54, 54, 54);
    text-decoration: none;
    padding: 10px;
    background-image: linear-gradient(to top, rgb(255, 255, 255, 0.7) 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: top;
    transition: background-position 0.5s ease-in-out; /** I've changed the time for demo purposes **/
    border-radius: 10% 30% 50% 70%;
}

.menu-icon:hover{
    color: rgb(54, 54, 54);
    text-decoration: none;
    background-position: bottom;
    border-radius: 10% 30% 50% 70%;
}

.contact-icon {
    color: rgb(54, 54, 54);
    text-decoration: none;
    padding: 10px;
    background-image: linear-gradient(to top, #8fffaf8d 50%, transparent 50%);
    background-size: 100% 200%;
    background-position: top;
    transition: background-position 0.5s ease-in-out; /** I've changed the time for demo purposes **/
    border-radius: 10% 30% 50% 70%;
}

.contact-icon:hover{
    color: rgb(54, 54, 54);
    text-decoration: none;
    background-position: bottom;
    border-radius: 10% 30% 50% 70%;
}

.nav-menu a {
    font-size: 14px;
    font-weight:bold;
    letter-spacing: 1px;
}

.hamburger {
    display: none;
}

@media screen and (max-width:800px) {
    .hamburger {
        display: block;
        color: black
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background: rgba(0,0,0, .75);
    }

    .menu-icon {
        background-image: none;
        color: #8fffaf;
    }

    .menu-icon:hover{
        color: #8fffaf;
        background-image: none;
    }


    .contact-icon {
        background-image: none;
        color: #8fffaf;
    }

    .contact-icon:hover{
        color: #8fffaf;
        background-image: none;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 2rem;
    }
}







