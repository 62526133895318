.sidebar { /* Full-height: remove this if you want "auto" height */
  width: 14%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 0; /* Stay on top */
  bottom: 0; 
  left: 0;
  background-color: transparent; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  color: white;
}

.icons {
    bottom: 0;
    left: 0;
    margin: 10%;
}

.icon-row {
    margin: 6%;
}

.icon-row:hover {
    animation: wobble 100000s ease infinite;
}

@keyframes wobble {
  0% { transform: translateX(0%); }
  0.001% { transform: translateX(-5%) rotate(-5deg); }
  100% { transform: translateX(-5%) rotate(-5deg); }
}

@media only screen and (max-width: 800px) {
  .sidebar {
    margin: 1px;
    width: 25%;
    z-index: 5;
    bottom: 1%;
  }
}